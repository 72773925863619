import React from 'react';
import axios from 'axios';
import API_URL from './info.js';
import myRow from './myRow.js';


function myProducts(p, k) {
    return (
        <div class="container box style1 contPoryec  cardWithShadow ">
            <div class="row ">
                <div class="col-md-4 ">
                    <a href={p.url} target='_blank' className='link_ImgProyect'>      
                    <div className='contImg '>
                    <img src={API_URL + 'media/' + p.image} className='imagen_prouyect'  alt="" />
                    {/* <img src={API_URL + p.image} alt="" /> */}
                    </div>                  
                        
                    </a>
                </div>
                <div class="col-md-8">
                    <div className='contParraProyect'>
                        <p className='h5'>{p.name}</p>
                        {/* <p>{k.filter(el => el.id === p.contraparte)[0].name}</p> */}
                        <p>{p.start} {p.start !== p.end ? "-" : ""} {(p.end === 2100) || (p.start === p.end) ? "" : p.end}</p>
                        {/* <p>{(p.start === p.end) || (p.end === 2100) ? p.start : p.start + " - " + p.end}</p> */}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p>{p.description}</p>
                </div>
            </div>
        </div>)  
        

    
}


function myTasks(tasks, i) {
    //console.log(tasks);
    return (
        <div class="row gtr-0 contProyecDestacado">
            {tasks.map((t, k) => {
                return (
                    <div class="col-12 col-md-6 contCardPoryectMore ">
                        <div class="card card-95 confi_z_index cardContCardPoryectMore cardWithShadow ">
                            <div class="card-body ">
                                <p class="card-subtitle mb-2 h5">{t.name}</p>
                                <p>{!t.generic ? i.filter(el => el.id === t.contraparte)[0].name : ""}</p> 
                                <p class="card-text">{ !t.generic ? t.year : ""}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


function myWorks(w, k) {
    //console.log(w);
    return (
        <article class="container box style2">
            <div className='section-title'>
                <h2>{w.name}</h2>
                <h4>{w.position}</h4>
                <p>{w.start} {w.start !== w.end ? "-" : ""} {(w.end === 2100 || w.start === w.end) ? "" : w.end}</p>
                {/* <h6>{w.start === w.end ? w.start : w.start + " - " + w.end}</h6> */}
            </div>
            <div class="inner gallery">
                {myRow(w.tareas, null, myTasks, k)}
            </div>
        </article>
    )
}

class Proyectos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            empleos: [],
            productos: [],
        };
    }

    componentDidMount() {
        axios.get(API_URL + "v1/" + 'projects/')
            .then(response => {
                console.log(response);
                this.setState({
                    empleos: response.data.empleos,
                    productos: response.data.productos,
                    institutions: response.data.institutions,
                });
            }
            ).catch(error => {
                // console.log(error);
            }
            ).finally(() => {
                //console.log(this.state.institutions.filter(i => i.id === 1)[0].name);
            }
            );
    }

    render() {
        return (
            <div>
                <article id='proyectos' class="container box style2">
                    <div className='section-title'>
                        <h2>Proyectos destacados</h2>
                    </div>
                </article>
                {this.state.productos.map((p) => myProducts(p, this.state.institutions))}
                {this.state.empleos.map((w) => myWorks(w, this.state.institutions))}
            </div>
        );
    }
}


export default Proyectos;