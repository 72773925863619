import React from "react";
import axios from "axios";
import API_URL from "./info.js";
import myRow from "./myRow.js";



function myFormation(formation, i) {
    return (
        <div className="col-12 col-md-6 cardGeneric " key={formation.id}>
            <div className="card card-95 contCard  cardWithShadow">
                <div className="card-body">
                    <h5 className="card-title">{ formation.title }</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{i.institutions.filter(el => el.id === formation.institution)[0].name}</h6>
                    <p className="card-text">{ formation.end }</p>
                </div>
            </div>
        </div>


        // 
        




    )
};


class Formation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formations: [],
            institutions: [],
        };
    };

    componentDidMount() {
        axios.get(API_URL + "v1/" + 'formations/')
            .then(response => {
                //console.log(response);
                this.setState({
                    formations: response.data.formaciones,
                    institutions: response.data.institutions
                });
            }
            ).catch(error => {
                // console.log(error);
            }
            ).finally(() => {
                //console.log(this.state.institutions.filter(i => i.id === 1)[0].name);
            }
            );
    };

    render() {
        return (
            <div>
                <article id='formacion-academica' className="container box style2">
                    <div className="section-title">
                        <h2>Formación académica</h2>
                    </div>
                        {this.state.formations.map((f, k) => myRow(f, k, myFormation, {institutions: this.state.institutions}))}
                </article>
            </div>
        );
    };
};

export default Formation;