import React from 'react';
import axios from 'axios';
import API_URL from './info.js';


class Idiomas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idiomas: [],
        };
    };

    componentDidMount() {
        axios.get(API_URL + "v1/" + 'idiomas/')
            .then(response => {
                console.log(response);
                this.setState({
                    idiomas: response.data.idiomas,
                });
            }
            ).catch(error => {
                // console.log(error);
            }
            ).finally(() => {
                //console.log(this.state.institutions.filter(i => i.id === 1)[0].name);
            }
            );
    };

    render() {
        return (
            <article id='idiomas' className="container box style2">
                <div className="section-title">
                    <h2>Idiomas</h2>
                </div>
                <div className="inner gallery">
                    <div className="row gtr-0 justify-content-between">
                        {this.state.idiomas.map((idioma, k) => {
                            return (
                                <div className="col-12 col-md-4" key={k}>
                                    <div className="card card-95 confi_z_index contCardIdiomas cardWithShadow">
                                        <div className="card-body">
                                        <img src={API_URL + 'media/' + idioma.flag} className='img_Idiomas' alt=""/>
                                        {/* Puse esta bandera como ejemplo, luego puede acceder a la apis que traes los datos*/}
                                            <h5 className="card-title">{idioma.name} <br></br>
                                             <i class="fa-solid fa-arrow-down-long"></i></h5>
                                                <h6 className="card-subtitle mb-2 text-muted">{ idioma.type }</h6>
                                            <p className="card-text">
                                           
                                                <strong>Leído<i class="fa-solid fa-arrow-right-long arrow_"></i> </strong>
                                                <span>{ idioma.read }</span>
                                            </p>
                                            <p className="card-text">
                                                <strong>Escrito <i class="fa-solid fa-arrow-right-long arrow_"></i> </strong>
                                                <span>{ idioma.write }</span>
                                            </p>
                                            <p className="card-text">
                                                <strong>Hablado <i class="fa-solid fa-arrow-right-long arrow_"></i> </strong>
                                                <span>{ idioma.speak }</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                </div>
            </article>
        );
    };
}

export default Idiomas;