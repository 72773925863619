import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import API_URL from "../info.js";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            error: false,
            backdropOpen: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleAlerts = this.handleAlerts.bind(this);
    };

    handleAlerts() {
        this.setState({
            success: false,
            error: false,
        });
    };

    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state);
        this.setState({ success: false, error: false, backdropOpen: true });
        axios.post(API_URL + "v1" + 'send-email/', this.state)
            .then(response => {
                // console.log(response);
                if (response.data.success) {
                    this.setState({ success: true, name: '', email: '', message: '' });
                }
            }).catch(error => {
                // console.log(error);
                this.setState({ error: true });
            }).finally(() => {
                this.setState({ backdropOpen: false });
            });
    }

    handleNameChange(event) {
        this.setState({ name: event.target.value });
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    handleMessageChange(event) {
        this.setState({ message: event.target.value });
    }

    render() {
        return (
            <article id='contactame' className="container box style3">
                <header>
                    <h2>Contáctame</h2>
                    <p>¿Quieres contratarme o ponerte en contacto conmigo por cualquier motivo?</p>
                </header>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.backdropOpen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box sx={{ width: '100%' }}>
                    <Collapse in={this.state.success}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={this.handleAlerts}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <Typography gutterBottom variant="h5" component="div">
                                Mensaje enviado correctamente!
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Gracias por contactarme.
                                Intentaré responderte lo antes posible.
                                Si no recibes respuesta, revisa el correo de <b>SPAM</b>.
                                Puedes escribirme directamente a <a href="mailto:me@dnldelarosa.com" target="_blank" rel="noreferrer">me@dnldelarosa.com</a>.
                            </Typography>
                        </Alert>
                    </Collapse>
                    <Collapse in={this.state.error}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={this.handleAlerts}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            severity="error"
                        >
                            <Typography gutterBottom variant="h5" component="div">
                                Algo salió mal!
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Haz click <a href="mailto:me@dnldelarosa.com" target="_blank" rel="noreferrer">aquí</a> para enviar un mensaje a mi dirección de correo electrónico (<a href="mailto:me@dnldelarosa.com" target="_blank" rel="noreferrer">me@dnldelarosa.com</a>).
                            </Typography>
                        </Alert>
                    </Collapse>
                </Box>
                <br />
                <form onSubmit={this.handleSubmit}>
                    <div className="row gtr-50">
                        <div className="col-6 col-12-mobile"><input type="text" className="text" name="name" placeholder="Nombre" value={this.state.name} onChange={this.handleNameChange} required /></div>
                        <div className="col-6 col-12-mobile"><input type="email" className="text" name="email" placeholder="Correo" value={this.state.email} onChange={this.handleEmailChange} required /></div>
                        <div className="col-12">
                            <textarea name="message" placeholder="Mensaje" value={this.state.message} onChange={this.handleMessageChange} required></textarea>
                        </div>
                        <div className="col-12">
                            <ul className="actions">
                                <li><input type="submit" value="Enviar mensaje" /></li>
                            </ul>
                        </div>
                    </div>
                </form>
            </article>
        );
    };
};

export default Contact;