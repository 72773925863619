

function myRow(element, k, myFunc, i) {
    return (
        <div className="row gtr-0 justify-content-between" key={k}>
            {element.map(e => myFunc(e, i))}
        </div>
    );
};

export default myRow;