import React from 'react';

import { Header } from './features/header/Header';
import { Navbar } from './features/header/Navbar';
import { Profile } from './features/header/Profile';
import { Footer } from './features/footer/Footer';
import Contact from './features/contact/Contact';
import Formation from './features/Formation';
import Idiomas from './features/Idiomas';
import Habilidades from './features/Habilidades';
import Proyectos from './features/Proyectos';

function App() {
  return (
    <>
	  <Header />
	  <Navbar />
	  <Profile />
	  <Formation />
	  <Idiomas />
	  <Habilidades />
	  <Proyectos />
	  <Contact />
	  <Footer />
    </>
  );
}

export default App;
