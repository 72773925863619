export function Navbar() {
    return (
        
		<nav id='inicio' className="navbar sticky-top navbar-expand-lg navbar-light bg-light">
        <nav className="navbar navbar-light bg-light">
            <a className="navbar-brand" href="#banner">
                {/* <img src="{% static 'mainsite/bootstrap-solid.svg' %}" width="30" height="30" className="d-inline-block align-top" alt=""> */}
                @dnldelarosa
            </a>
        </nav>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="icons navbar-nav ml-auto">
                <li>
                    <a href="mailto:me@dnldelarosa.com" target="_blank"  rel="noopener noreferrer" className="icon fa-envelope"><span className="label">Twitter</span></a>
                    <a href="https://twitter.com/dnldelarosa" target="_blank" rel="noopener noreferrer" className="icon brands fa-twitter"><span className="label">Twitter</span></a>
                    <a href="https://www.linkedin.com/in/dnldelarosa/" target="_blank" rel="noopener noreferrer" className="icon brands fa-linkedin-in"><span className="label">LinkedIn</span></a>
                    <a href="https://github.com/dnldelarosa" target="_blank" rel="noopener noreferrer" className="icon brands fa-github"><span className="label">Twitter</span></a>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                    <a className="nav-link" href="#banner">Inicio</a>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Navegar a</a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href="#formacion-academica">Formación académica</a>
                        <a className="dropdown-item" href="#idiomas">Idiomas</a>
                         <a className="dropdown-item" href="#habilidades">Habilidades</a>
                        <a className="dropdown-item" href="#proyectos">Proyectos</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#contactame">Contáctame</a>
                    </div>
                </li> 
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Blog</a>
                    <div className="dropdown-menu">
                        <a className="nav-link" target="_blank" rel="noreferrer" href="https://gcpareto.com/author/daniel/">Economía</a>
                        <div className="dropdown-divider"></div>
                        <a className="nav-link" target="_blank" rel="noreferrer" href="https://adatar.do/blog">Data Science</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
    );
};