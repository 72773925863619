import React from 'react';
import axios from 'axios';
import API_URL from './info.js';
import myRow from './myRow.js';



function mySkills(h, i) {
    return (
        // <div className="col-12 col-md-3 " key={h.id}>
        //     <div className="card cardHabilidades card-95">
        //         <div class="col-12">
        //             <a href={h.url} target='_blank' class="image fit imagen">
        //                 <img src={API_URL + "media/" + h.image} alt="" />
        //             </a>
        //         </div>
        //     {/* <img src={API_URL + h.image} className="card-img-top" alt={h.name} /> */}
        //         <a href={ h.url } rel="noopener noreferrer" >
        //             <div className="card-body">
        //                 <h5 className="card-title">{h.name}</h5>
        //                 <h6 className="card-subtitle mb-2 text-muted">{ h.level }</h6>
        //             </div>
        //         </a>
        //     </div>
        // </div>

        <div className="col-12 col-md-3 cardGeneric  " key={h.id}>
        <div className="card card-95 confi_z_index contCardHabili cardWithShadow ">
            <div className="card-body">
                <div class="col-12">
                    <a href={h.url} target='_blank' class="image fit imagen">
                        <img src={API_URL + "media/" + h.image} alt="" />
                    </a>
                </div>
            </div>
            <a href={ h.url } rel="noopener noreferrer" >
                    
                         <h5 className="card-title">{h.name}</h5>
                         <h6 className="card-subtitle mb-2 text-muted">{ h.level }</h6>
                     
                 </a>
        </div>
        </div>
    )
};


class Habilidades extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            habilidades: [],
        };
    };

    componentDidMount() {
        axios.get(API_URL + "v1/" + 'habilidades/')
            .then(response => {
                //console.log(response);
                this.setState({
                    habilidades: response.data.habilidades,
                });
            }
            ).catch(error => {
                // console.log(error);
            }
            ).finally(() => {
                //console.log(this.state.institutions.filter(i => i.id === 1)[0].name);
            }
            );
    };

    render() {
        return (
			<article id='habilidades' className="container box style2">
				<div className="section-title">
					<h2>Habilidades</h2>
				</div>
				<div className="inner gallery">
                    {this.state.habilidades.map((h, k) => myRow(h, k, mySkills, k))}
                </div>
            </article>
        );
    };
};


export default Habilidades;