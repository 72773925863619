
export function Header() {
    return (
        <section id="header">
            <header>
                <h1>Daniel E. de la Rosa</h1>
                <p>@dnldelarosa</p>
            </header>
            <footer>
                {/* <a href="#inicio" className="button style2">Continuar</a> */}
            </footer>
        </section>
    );
};