export function Profile() {
    return (
        <section id="banner">
            <header>
                <h2>Economista y científico de datos</h2>
            </header>
            <div className="contParraf_Profile">
            <p>
                Maestrando en Ciencias de Datos (MCD) del Instituto Tecnológico de Santo Domingo (INTEC). <br />
                Institución por la que que también cuenta con una especialidad en Matemáticas Puras y una Licenciatura en Economía. </p>
            <br />
            <p>
                En el sector público, ha trabajado en el Ministerio de Economía, Planificación y Desarrollo (MEPyD) y la
                Comisión Nacional de Defensa de la Competencia (PROCOMPETENCIA). Además de haber sido Consultor para el Ministerio de la Presidencia (MINPRE).
                En el sector privado, ha trabajado como consultor para múltiples instituciones.
            </p>
            <br />
            <p>
                Ha participado y colaborado en proyectos de investigación para instituciones como el Banco Mundial (BM), 
                Programa de las Naciones Unidas para el Desarrollo (PNUD) y la Universidad de la Ciudad de New York (The City University of New York, CUNY).
            </p >
            <br />
            <p>
                Es versado en el manejo de múltiples softwares estadísticos, econométricos y de inteligencia de negocios,
                procesos de extracción, manipulación y análisis de datos, así como la programación en R y Python.
            </p>
            </div>
            
            {/* 
        <footer>
            <a href="#first" className="button style2 scrolly">Act on this message</a>
        </footer> */}
        </section >
    )
}